import { Technology as MyType } from '@shapeable/adsw-types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
export const Technology: EntityConfig<MyType> = {
  name: 'Technology',
  type: 'taxonomy',
  includeInSearch: true,
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,
      banner: { type: 'banner' },
      url: { type: 'url', description: "Provide an external website URL which has more information about this {{TECHNOLOGY}}" },
      description: { type: 'longText' },
      instruments: { type: 'linkedMultiple', entityTypeName: 'Instrument' },
      feedEntries: { type: 'linkedMultiple', entityTypeName: 'FeedEntry' },

    }
  },
}; 