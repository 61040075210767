import { createApplication, entityConfigs, appSectionConfigs } from '@shapeable/core';
import { themes } from '@shapeable/theme';
import { ApplicationLogo, ApplicationLogoInverted } from './components';
import { filter, includes } from 'lodash';

const { Organisation, Trend, Topic } = entityConfigs;
import { Instrument, InstrumentType, Technology } from './config/';

const theme = themes.OC_THEME;

// pre-patch configs to ensure that derived properties are correctly created 

if (entityConfigs.Trend) {
  entityConfigs.Trend.label = 'Innovation System';
  entityConfigs.Trend.pluralLabel = 'Innovation Systems';
  entityConfigs.Trend.slug = 'innovation-system';
  entityConfigs.Trend.pluralSlug = 'innovation-systems';
}

if (entityConfigs.Topic) {
  entityConfigs.Topic.label = 'Focus Area';
  entityConfigs.Topic.pluralLabel = 'Focus Areas';
  entityConfigs.Topic.slug = 'focus-area';
  entityConfigs.Topic.pluralSlug = 'focus-areas';
}

if (Organisation) {
  Organisation.extraFields = {
    trends: { type: 'linkedMultiple', entityTypeName: 'Trend' },
    topics: { type: 'linkedMultiple', entityTypeName: 'Topic' },
    technologies: { type: 'linkedMultiple', entityTypeName: 'Technology' },
  }
}

if (Trend) {
  Trend.extraFieldSets = [
    { name: 'content', label: 'Content' },
    { name: 'horizons', label: 'Horizons' },
    { name: 'library', label: 'Library' },
  ];

  Trend.extraFields = {
    horizons: { type: 'linkedTable', entityTypeName: 'Horizon', fieldSet: 'horizons' },
    citations: { type: 'linkedMultiple', entityTypeName: 'Citation', fieldSet: 'library' },
    reportsIntro: { type: 'longText', fieldSet: 'content', description: 'Provide an optional intro for the Reports tab for this {{TREND}}.'  },
  }
}

if (Topic) {
  Topic.extraFieldSets = [
    { name: 'content', label: 'Content' },
  ];

  Topic.extraFields = {
    policyTrends: { type: 'longText', fieldSet: 'content' },
    fundingTrends: { type: 'longText', fieldSet: 'content'  },
    partnershipTrends: { type: 'longText', fieldSet: 'content' },

    technologiesIntro: { type: 'longText', fieldSet: 'content', description: 'Provide an optional intro for the Technologies tab for this {{TOPIC}}.' },
    instrumentsIntro: { type: 'longText', fieldSet: 'content',  description: 'Provide an optional intro for the Instruments tab for this {{TOPIC}}.' },
    stakeholdersIntro: { type: 'longText', fieldSet: 'content', description: 'Provide an optional intro for the Stakeholders tab for this {{TOPIC}}.' },
    libraryIntro: { type: 'longText', fieldSet: 'content', description: 'Provide an optional intro for the Library tab for this {{TOPIC}}.' },

    technologies: { type: 'linkedMultiple', entityTypeName: 'Technology' },
    instruments: { type: 'linkedMultiple', entityTypeName: 'Instrument' },
    citations: { type: 'linkedMultiple', entityTypeName: 'Citation', fieldSet: 'library' },
    fileAssets: { type: 'linkedMultiple', entityTypeName: 'FileAsset', fieldSet: 'library' },
  }
}

appSectionConfigs.challengesGoals.label = 'Navigator';

appSectionConfigs.challengesGoals.children = [
  ...filter(appSectionConfigs.challengesGoals.children, child => includes(['trends', 'topics', 'sdgs'], child.id)),
  {
    id: 'instruments',
    type: 'list',
    entityTypeName: 'Instrument',
  },
  {
    id: 'technologies',
    type: 'list',
    entityTypeName: 'Technology',
  },

];

const myEntityConfigs = { 
  ...entityConfigs,
  Instrument,
  InstrumentType,
  Technology,
};

export const application = createApplication({
  theme,
  entityConfigs: myEntityConfigs,
  options: {
    systemsMapping: 'full',
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});

/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

delete entities.Challenge;
delete entities.SubTopic;
delete entities.KeyIssue;
delete entities.ImpactStory;