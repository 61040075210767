import { Instrument as MyType } from '@shapeable/adsw-types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
export const Instrument: EntityConfig<MyType> = {
  name: 'Instrument',
  type: 'taxonomy',
  includeInSearch: true,
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,
      banner: { type: 'banner' },
      description: { type: 'longText' },
      types: { type: 'linkedMultiple', entityTypeName: 'InstrumentType' },
    }
  },
}; 